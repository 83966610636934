import "./App.css";
import Home from "./Components/Home/Home";
import Faqs from "./Components/Faq/Subpage";
import { Suspense, lazy } from "react";
import AuthLogin from "./Auth/AuthLogin";
import AuthSignup from "./Auth/AuthSignup";

import Lostpassword from "./Components/Home-password/Lostpassword";
import Resetpassword from "./Components/Home-password/Resetpassword";

import Logout from "./Components/Login/Logout";

import Resourcescallback from "./Resource/resource-callback";
import Resourcescthankyou from "./Resource/resource-thank-you";
import SkeletonWL from "./Components/Skeleton/Withoutlogin";

import Datasciencebook from "./pages/ebook/datascienceebook";
import Webinar from "./pages/webinar/webinar";
import OutlinedCard from "./Components/Broucher/broucher";
import Payment from "./pages/webinar/payment";
import YourComponent from "./pages/demo/YourComponent";
import Thankyou from "./pages/webinar/thankyou-new";

import { Routes, Route, Navigate, useParams } from "react-router-dom";
import SearchedPage from "./Components/Home/SearchedPage/SearchedPage";
import NotFound from "./Components/NotFound/NotFound";
import { Privacy, Referral, Terms, Admission, Cancellation, Refund } from "./pages/Policies";

import { Inclusive } from "./Components/Program";

const Successstories = lazy(() =>
  import("./Components/success-stories/success-stories")
);
const Whyinsaidhome = lazy(() =>
  import("./Components/WhyInsaid/Whyinsaidhome")
);
const Career = lazy(() => import("./Components/Careers/Career"));
const About = lazy(() => import("./Components/About/About"));
const Faq = lazy(() => import("./Components/Faq/MasterFaq"));
const Keymodule = lazy(() => import("./Resource/key-modules"));
const Factsheet = lazy(() => import("./Resource/fact-sheet"));
const Ebook = lazy(() => import("./Resource/ebook"));
const Gallery = lazy(() => import("./Components/Gallery/Gallery"));
const Programs = lazy(() => import("./Components/Programs/Programs"));
const CTAThankYou = lazy(() => import("./pages/cta-thank-you/cta-thank-you"));
const Refer = lazy(() => import("./Components/ReferAndEarn/Refer"))

const Redirect = () => {
  const { id } = useParams();
  return <Navigate to={`/programs/${id}`} replace />;
};

function App() {
  return (
    <>
      <Routes>
        {/* {localStorage.getItem('user_id')} */}
        {/* <Route path='/WordpressPasswordGenerator' element={<WordpressPasswordGenerator/>} />
        <Route path='/testing2' element={<PDFDragDrop/>} /> */}
        <Route path="*" element={<NotFound />} />
        <Route index path="/" element={<Home />} />
        <Route path="/Myaccount" element={<Navigate to={"/login"} />} />

        <Route path="webinar/:id" element={<Webinar />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="demo/:id" element={<YourComponent />} />

        {/* program pages routes */}
        <Route
          path="/programs/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Programs />
            </Suspense>
          }
        />
        {/* <Route path="programs/:id" element={<Programs />} /> */}
        <Route path="program/inclusion/:id" element={<Inclusive />} />

        {/* filter page routes */}
        <Route path="/filters" element={<SearchedPage />} />

        {/* admission policies routes */}
        <Route path="/policies/:id" element={<Admission />} />
        {/* terms and policies routes */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms/privacy" element={<Privacy />} />
        <Route path="/terms/refund" element={<Refund />} />
        <Route path="/terms/cancellation" element={<Cancellation />} />
        <Route path="/terms/referral" element={<Referral />} />

        <Route
          path="/accredian/:id"
          element={<Redirect />}
        />
        <Route
          path="iit/:id"
          element={<Redirect />}
        />
        <Route
          path="iit-kanpur/:id"
          element={<Redirect />}
        />
        <Route
          path="imi/:id"
          element={<Redirect />}
        />
        <Route
          path="iim/:id"
          element={<Redirect />}
        />
        <Route
          path="iim-amritsar/:id"
          element={<Redirect />}
        />
        <Route
          path="XLRI/:id"
          element={<Redirect />}
        />
        <Route
          path="spj/:id"
          element={<Redirect />}
        />
        <Route
          path="/Career"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Career />
            </Suspense>
          }
        />
        <Route path="/datascience-ebook" element={<Datasciencebook />} />
        <Route
          path="/About"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/Faq"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path="/Successstories"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Successstories />
            </Suspense>
          }
        />
        <Route path="/Faqs/:id" element={<Faqs />} />
        <Route
          path="/whyaccredian"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Whyinsaidhome />
            </Suspense>
          }
        />
        <Route
          path="/Gallery"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/key-modules-resource/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Keymodule />
            </Suspense>
          }
        />
        <Route
          path="/resource-fact-sheet/:id"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Factsheet />
            </Suspense>
          }
        />
        <Route
          path="/ebook"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Ebook />
            </Suspense>
          }
        />
        <Route
          path="/cta-thank-you"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <CTAThankYou />
            </Suspense>
          }
        />
        <Route
          path="/referandearn"
          element={
            <Suspense fallback={<SkeletonWL />}>
              <Refer />
            </Suspense>
          }
        />

        <Route path="/resource-callback" element={<Resourcescallback />} />
        <Route path="/resource-thank-you" element={<Resourcescthankyou />} />
        <Route path="/Login" element={<AuthLogin />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Signup" element={<AuthSignup />} />
        <Route path="/Lost-password" element={<Lostpassword />} />
        <Route path="/Reset-password/:id" element={<Resetpassword />} />

        <Route path="/brochure/:id" element={<OutlinedCard />} />
        <Route path="/Thankyou" element={<Thankyou />} />
      </Routes>
    </>
  );
}
export default App;
