import Drawer from "@mui/material/Drawer";
import { TbLogin2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import AccordionHam from "./AccodionHam";
import { GrAnnounce } from "react-icons/gr";

export default function Ham({
  isOpen,
  setIsOpen,
  userInfo,
  LogOut,
  isLoginOpen,
  setIsLoginOpen,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleLogin = () => {
    setIsOpen(false);
    setIsLoginOpen(!isLoginOpen);
  };

  return (
    <div className="lg:hidden">
      <Drawer anchor={"left"} open={isOpen} onClose={toggleDrawer(false)}>
        <div className="w-[300px] h-screen bg-white p-4 font-circular">
          <div className="w-full h-full flex flex-col">
            <div className="w-full flex justify-center items-center mt-2">
              <img
                src="/Rcimages/logo.png"
                alt="logo"
                className="max-w-[130px] w-full"
              />
            </div>
            <AccordionHam />
            {/* <div className="flex items-center gap-2 my-2">
              <GrAnnounce />
              <Link to={"/referandearn"} className="text-lg font-semibold">
                Refer and Earn
              </Link>
            </div> */}
            {userInfo ? (
              <>
                <Link
                  to={"/MyAccount"}
                  className="py-2 px-4 hover:text-universal hover:bg-universal/15 rounded-md cursor-pointer w-full text-center"
                >
                  <h2 className="font-semibold capitalize">My Account</h2>
                </Link>
                <button
                  className="py-2 px-4 hover:text-red-500 hover:bg-red-400/30 rounded-md cursor-pointer"
                  onClick={() => {
                    LogOut("token", "accredian.com");
                  }}
                >
                  <h2 className="font-semibold capitalize">Logout</h2>
                </button>
              </>
            ) : (
              <>
                {/* <Link to="/login" className='md:hidden'> */}
                <div
                  onClick={handleLogin}
                  className="w-full justify-center md:hidden flex items-center gap-2 text-lg text-universal px-4 py-1 bg-blue-200/15 rounded-sm"
                >
                  <TbLogin2 />
                  <h1 className="font-semibold">Login</h1>
                </div>
                {/* </Link> */}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://trial.accredian.com"
                  className="mt-2 lg:hidden"
                >
                  <div className="w-full justify-center flex items-center gap-2 text-lg text-universal px-4 py-1 bg-blue-200/15 rounded-sm">
                    <h1 className="font-semibold">Try for free</h1>
                  </div>
                </a>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
