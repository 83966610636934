import React, { useCallback, useEffect, useState } from 'react'
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import ExploreDropDown from "../Navbar3/ExploreDropdown/ExploreDropDown"
import "../Navbar.css"
import { IoIosArrowDown } from 'react-icons/io';
import Ham from '../../Drawers/Drawer/Ham'
import ExploreDrawer from '../../Drawers/Drawer/ExploreDrawer';
import { useDisclosure, ChakraProvider, PopoverContent, PopoverTrigger, Popover, Avatar } from "@chakra-ui/react";
import { Link, useNavigate } from 'react-router-dom';
import { NAV_ITEMS } from '../../../Assets/constants/constants';
import NavItem from '../Navbar3/NavItem';
import axios from 'axios';

export default function Navbar({ userInfo }) {

  const [isOpenHam, setIsOpenHam] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false)
  const [isCookie, setIsCookie] = useState(document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1'))
  const [filepath, setFilepath] = useState(localStorage.getItem("profileURL") ? localStorage.getItem("profileURL") : "");
  const profile_var = localStorage.getItem("profile")

  useEffect(() => {
    if (!isCookie) {
      setIsCookie("")
      localStorage.removeItem("token")
    }
  }, [isCookie])

  const fetchData = useCallback(async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      // console.log(res);
      if (res.data.status == "300") {
        setFilepath("");
      } else {
        localStorage.setItem("profileURL", res.data[0].filename)
        setFilepath(res.data[0]?.filename);
      }
    }).catch((err) => {
      console.log(err);
    })
  }, [])
  useEffect(() => {
    if (!localStorage.getItem("profileURL")) {
      fetchData()
    }
  }, [profile_var, fetchData]);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClick = () => {
    onOpen()
  }

  const navigate = useNavigate()

  const LogOut = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    localStorage.removeItem("user_id");
    localStorage.clear();
    // setUserInfo("");
    navigate(`/Login`);
  };

  return (
    <>
      <div
        className="nav-3 flex justify-center py-[0.6rem] w-full px-4 xl:px-12"
      >
        <div className='nav-3-inner'>
          <div className='nav-3-left'>
            <Link to={'/'} style={{ textDecoration: "none" }}>
              <div className='max-w-[125px] w-full'>
                <img className='w-full' alt='logo' src='/Rcimages/logo.png' />
              </div>
            </Link>
            <ExploreDropDown />
          </div>
          <div className='nav-3-right'>
            <div className='nav-3-right-inner'>
              <ChakraProvider>
                {NAV_ITEMS.map((item, index) => (
                  <NavItem key={`nav_item_parent_key_${index}`} item={item} />
                ))}
              </ChakraProvider>
            </div>
            {isCookie ? (
              <div className='md:flex hidden'>
                <ChakraProvider>
                  <Popover trigger="hover" openDelay={100}>
                    <PopoverTrigger>
                      <div className='flex items-center gap-2 max-w-[160px] cursor-pointer hover:bg-blue-200/30 py-2 px-3 rounded-md'>
                        <Avatar bg='#1A73E8' size={"sm"} src={filepath ? filepath : ""} />
                        <h2 className='font-medium truncate'>{localStorage.getItem("name")}</h2>
                      </div>
                    </PopoverTrigger>
                    <PopoverContent top={3} className='pop-content-user'>
                      <a href={'https://dashboard.accredian.com'} className='py-2 px-4 hover:text-universal hover:bg-universal/15 rounded-md cursor-pointer w-full'>
                        <h2 className='font-medium capitalize'>My Account</h2>
                      </a>
                      <button className='py-2 px-4 hover:text-red-500 text-start hover:bg-red-400/30 rounded-md cursor-pointer' onClick={() => { LogOut('token', 'accredian.com') }}>
                        <h2 className='font-medium capitalize'>Logout</h2>
                      </button>
                    </PopoverContent>
                  </Popover>
                </ChakraProvider>
              </div>
            ) : (
              <div className='hidden md:flex items-center justify-center gap-[1rem]'>
                <Link to="/login">
                  <button className=' px-[18px] py-[8px] bg-slate-400/20 rounded-md font-medium text-base text-nowrap'>
                    Login
                  </button>
                </Link>
                <a rel="noreferrer" target='_blank' href="https://trial.accredian.com" className='lg:block hidden'>
                  <button className='px-[18px] py-[8px] bg-universal hover:bg-darkBlue text-white rounded-md font-medium text-base text-nowrap'>
                    Try for free
                  </button>
                </a>
              </div>
            )}
            <button className="explore-2  bg-universal text-white md:hidden flex items-center gap-1 font-medium px-3 py-1 rounded-sm"
              onClick={() => handleClick()}
            >
              Explore
              <IoIosArrowDown className="-rotate-90" />
            </button>
            <HiOutlineMenuAlt3 size={28} className='text-neutral-800 lg:hidden block cursor-pointer' onClick={() => setIsOpenHam(!isOpenHam)} />
          </div>
        </div>
      </div>
      <Ham setIsLoginOpen={setIsLoginOpen} isLoginOpen={isLoginOpen} LogOut={LogOut} userInfo={userInfo} isOpen={isOpenHam} setIsOpen={setIsOpenHam} />
      <ExploreDrawer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  )
}
