import { GiMaterialsScience } from "react-icons/gi";
// import { IoBagOutline } from "react-icons/io5";
import { GoProjectRoadmap } from "react-icons/go";
import { FaConnectdevelop } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { GrResources } from "react-icons/gr";
import { GrAnnounce } from "react-icons/gr";
import { TbInfoSquareRounded } from "react-icons/tb";
import { PiStrategy } from "react-icons/pi";
import { MdOutlineLeaderboard } from "react-icons/md";
import { IoCardOutline } from "react-icons/io5";

export const STREAMS = [
  {
    category: "data science",
    icon: GiMaterialsScience,
    order: 9,
  },
  {
    category: "product management",
    icon: GoProjectRoadmap,
    order: 1,
  },
  // {
  //   category: "General Management",
  //   icon: IoBagOutline,
  //   order: 3,
  // },
  {
    category: "digital Transformation",
    icon: FaConnectdevelop,
    order: 8,
  },
  {
    category: "business management",
    icon: RiMoneyDollarCircleLine,
    order: 4,
  },
  {
    category: "project management",
    icon: GoProjectRoadmap,
    order: 7,
  },
  {
    category: "strategy & leadership",
    icon: PiStrategy,
    order: 2,
  },
  {
    category: "senior management",
    icon: MdOutlineLeaderboard,
    order: 6,
  },
  {
    category: "fintech",
    icon: IoCardOutline,
    order: 5,
  },
  {
    category: "business analytics",
    icon: FaConnectdevelop,
    order: 10,
  },
];

export const VERTICALS = [
  "data science",
  "Product Management",
  "business management",
  "Project Management",
  // "General Management",
  "Digital Transformation",
  "Strategy & Leadership",
  "Senior Management",
  "Fintech",
  "Business Analytics"
  // "Strategic Management"
];

export const REFER_BAR_LINK= [
  {
    name: "Refer",
    scrollTo: "referHero",
  },
  {
    name: "Benefits",
    scrollTo: "benefitsSection",
  },
  {
    name: "FAQ's",
    scrollTo: "faqsSection",
  },
  {
    name: "Support",
    scrollTo: "supportSection",
  },
]

export const NAV_ITEMS = [
  {
    label: "Refer and Earn",
    icon: GrAnnounce,
    href: "/referandearn",
  },
  {
    label: "Resources",
    icon: GrResources,
    children: [
      {
        label: "Key modules",
        children: [
          {
            label: "Data science",
            href: "/key-modules-resource/DS",
          },
          {
            label: "Product management",
            href: "/key-modules-resource/PM",
          },
          {
            label: "general management",
            href: "/key-modules-resource/GM",
          },
          // {
          //   label: "Business Analytics",
          //   href: "/key-modules-resource/BA",
          // },
          {
            label: "Digital Transformation",
            href: "/key-modules-resource/DT",
          },
          {
            label: "Project Management",
            href: "/key-modules-resource/PMT",
          },
          {
            label: "Strategic & Leadership",
            href: "/key-modules-resource/ST",
          },
          {
            label: "Business Management",
            href: "/key-modules-resource/BM",
          },
          {
            label: "Senior Management",
            href: "/key-modules-resource/SMP",
          },
        ],
      },
      {
        label: "Fact sheet",
        children: [
          {
            label: "Data science",
            href: "/resource-fact-sheet/DS",
          },
          {
            label: "Product management",
            href: "/resource-fact-sheet/PM",
          },
          {
            label: "general management",
            href: "/resource-fact-sheet/GM",
          },
          // {
          //   label: "Business Analytics",
          //   href: "/resource-fact-sheet/BA",
          // },
          {
            label: "Digital Transformation",
            href: "/resource-fact-sheet/DT",
          },
          {
            label: "Project Management",
            href: "/resource-fact-sheet/PMT",
          },
          {
            label: "Strategic & Leadership",
            href: "/resource-fact-sheet/ST",
          },
          {
            label: "Business Management",
            href: "/resource-fact-sheet/BM",
          },
          {
            label: "Senior Management",
            href: "/resource-fact-sheet/SMP",
          },
        ],
      },
      {
        label: "E-book",
        href: "/ebook",
      },
      {
        label: "Blog",
        redirect: true,
        href: "https://blog.accredian.com/",
      },
      {
        label: "Success Stories",
        href: "/Successstories",
      },
      {
        label: "Glossary",
        children: [
          {
            label: "Data science",
            redirect: true,
            href: "https://glossary.accredian.com/DS",
          },
          {
            label: "Product management",
            redirect: true,
            href: "https://glossary.accredian.com/PM",
          },
          {
            label: "general management",
            redirect: true,
            href: "https://glossary.accredian.com/GM",
          },
          {
            label: "Business Analytics",
            redirect: true,
            href: "https://glossary.accredian.com/BA",
          },
        ],
      },
      {
        label: "Master FAQs",
        href: "/Faq",
      },
    ],
  },
  {
    label: "About us",
    icon: TbInfoSquareRounded,
    children: [
      {
        label: "For corporate",
        redirect: true,
        href: "https://corporate.accredian.com",
      },
      {
        label: "why accredian",
        href: "/whyaccredian",
      },
      {
        label: "Career",
        href: "/Career",
      },
      {
        label: "vision & mission",
        href: "/About",
      },
    ],
  },
];

export const DEPARTMENTS = [
  {
    value: "management",
    label: "Management",
  },
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "academic operations",
    label: "Academic Operations",
  },
  {
    value: "product",
    label: "Product",
  },
  {
    value: "human resource",
    label: "Human Resource",
  },
  {
    value: "growth marketing",
    label: "Growth Marketing",
  },
  {
    value: "web development",
    label: "Web Development",
  },
  {
    value: "operations",
    label: "Operations",
  },
  {
    value: "strategy & new initiatives",
    label: "Strategy & New Initiatives",
  },
];

export const TESTIMONIALS = [
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni1.webp",
    review:
      "I would like to thank the Accredian team for keeping me busy with material and helping me develop the discipline needed to become a Data Scientist.",
    name: "Anirudha Acharya",
    designation: "Data Scientist",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo1.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni2.webp",
    review:
      "Accredian alleviated my lack of confidence coming from a non-coding background. Eventually, I became sure I'll do justice to Data Science projects.",
    name: "Lalit Garg",
    designation: "Assistant General Manager",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo2.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni3.webp",
    review:
      "I come from a statistics background with no exposure to coding. I was fortunate enough to come across Accredian which provided the right pace and right guidance.",
    name: "Jigna Thacker",
    designation: "Group Director Analytics",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo3.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni4.webp",
    review:
      "Accredian conducts different helpful sessions like Fireside chat and Industry Speakers to keep us informed of the world moving into something that we are far away from.",
    name: "Sanjit Ghosh",
    designation: "Senior, Project Manager",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo5.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni5.webp",
    review:
      "Accredian has designed the course after thorough research in a structured and well-thought-out way. It helps you in the real-world and practical aspects of your actual business or work.",
    name: "Sunitha Sharma",
    designation: "Assistant Vice President",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo6.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni6.webp",
    review:
      "Accredian faculty is talented and friendly and the curriculum is helpful. The initial confidence-building comes from the starter kits. They are extremely user-friendly.",
    name: "Nilesh Joshi",
    designation: "Vice President",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo7.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni7.webp",
    review:
      "I found Accredian is the best as compared to the other online programs. Accredian’s curriculum is way ahead than what other institutes are providing.",
    name: "Siboli Mukherjee",
    designation: "Data Analyst",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo8.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni8.webp",
    review:
      "Accredian proved to be the right platform to bridge all gaps and helped me achieve my main goal, which was to work as a Data Scientist.",
    name: "Rahul Sinha",
    designation: "Data Analytics Manager",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo9.webp",
  },
  {
    photo_url:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumni9.webp",
    review:
      "I am the National Head of Strategy for Kinetic Worldwide with little, prior coding experience. I owe my learning & inquisitiveness to Accredian and it's team of coders who is highly responsive.",
    name: "Arijit Chakrabarti",
    designation: "National Head of Strategy",
    company_logo:
      "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/home-page-images/alumnilogo10.webp",
  },
];


// not available right now

// {
//   "status": "Best Selling",
//   "university_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitg-logo.webp",
//   "brochureHeader": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
//   "university_name": "E&ICT Academy IIT Guwahati",
//   "program_name": "Executive Program in Data Science and Business Analytics",
//   "duration": "11 Months",
//   "batch_type": "Online",
//   "id": "executive-program-in-ds-ba",
//   "category": "data science",
//   "tags": [
//     "Data Manipulation",
//     "Exploratory Data Analysis",
//     "Data Visualization",
//     "Python Programming",
//     "Statistics",
//     "Machine Learning",
//     "Supervised Machine Learning",
//     "Unsupervised Machine Learning",
//     "Clustering Algorithms",
//     "Ensemble Learning",
//     "Optimization",
//     "Classification",
//     "Regression",
//     "Business Analytics",
//     "Database Management",
//     "Marketing & Retail Analytics",
//     "Supply Chain & Logistics",
//     "Web & Social Media Analysis"
//   ],
//   "cover_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/frontend-card-images/img-7.webp",
//   "brochure": {
//     "pdf": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-BA-11-months.pdf",
//     "source": "Business Analytics Brochure"
//   }
// },
// --------------------------------------------------
// {
//   "status": "Best Selling",
//   "brochureHeader": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/iitg.png",
//   "university_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iitg-logo.webp",
//   "university_name": "E&ICT Academy IIT Guwahati",
//   "program_name": "Executive Program in Data Science and AI",
//   "duration": "12 Months",
//   "batch_type": "Online",
//   "id": "executive-program-in-ds-and-ai-advance",
//   "category": "data science",
//   "tags": [
//     "Data Manipulation",
//     "Exploratory Data Analysis",
//     "Data Visualization",
//     "Python",
//     "Statistics"
//   ],
//   "cover_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/frontend-card-images/img-1.webp",
//   "brochure": {
//     "pdf": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIT-Executive-Program-in-DS-AI-12-months.pdf",
//     "source": "Data Science Brochure"
//   }
// },
// {
//   "status": "New Launch",
//   "university_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/iimv-brochure-logo.webp",
//   "university_name": "IIM Visakhapatnam",
//   "program_name": "PG Certificate Program in Strategic Product Management",
//   "duration": "10 Months",
//   "batch_type": "Online",
//   "brochureHeader": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/IIMV_LOGO.png",
//   "id": "pg-certificate-program-in-strategic-product-management",
//   "category": "product management",
//   "tags": [
//     "Strategic Thinking",
//     "Product Vision and Mission",
//     "Competitor Analysis and Market research",
//     "Business Model Canvas and Value Proposition",
//     "Product Strategy and Roadmap",
//     "Customer Segmentation",
//     "Prioritization and Roadmapping",
//     "Product Market Fit",
//     "Quantitative Metrics",
//     "MVP",
//     "Design Thinking and UI/UX",
//     "A/B Testing",
//     "Waterfall & Agile Methodology",
//     "Scrum & Kanban",
//     "Product Marketing & Branding Strategies",
//     "User Acquisition & Retention Channels",
//     "Launch Planning & Execution Tactics",
//     "Measuring GTM Success & Growth Hacking",
//     "Product Metrics and KPIs",
//     "Data Analysis and Storytelling",
//     "Psychological Pricing & Consumer Behavior",
//     "A/B Testing & Data-Driven Pricing Decisions",
//     "Advanced Pricing Strategies & Monetization Models",
//     "Global Product Management",
//     "GTM for Global Markets"
//   ],
//   "cover_image": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/frontend-card-images/img-11.webp",
//   "brochure": {
//     "pdf": "https://storage.googleapis.com/accredian-assets/Frontend_Assests/PDF/IIMV-SPM-Brochure-v8.pdf",
//     "source": "Strategic Product Management brochure",
//     "campaign_name": "PM-IIMV-10 Months"
//   }
// },
