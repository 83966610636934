import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
});

const userReducers = userSlice.reducer;

const userActions = userSlice.actions;

export { userReducers, userActions };
