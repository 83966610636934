import React, { Suspense, useState } from "react";
import Rcta from "../cta/request_a_call_back";
import Wrapper from '../Wrappers/Wrapper'

import { Hero, Info, Intro, PlacementSupport, Programs, Stats, Testimonials } from "./"

const Home = () => {
  const [cta, setCta] = useState(false);
  const RcbhandleClose = () => {
    setCta(false);
  };
  return (
    <>
      <Wrapper>
        <Hero />
        <Info />
        <Programs />
        <Intro />
        <PlacementSupport />
        <Stats />
        <Suspense fallback={<>Loading...</>}>
          <Testimonials />
        </Suspense>
      </Wrapper>
      <Rcta open={cta} handleClose={RcbhandleClose} />
    </>
  );
};

export default Home;
